// React Dependencies
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { Button, Dropdown, Form, Col, Modal, Row, ListGroup, Alert } from 'react-bootstrap';
import ReactStars from 'react-stars';
import SignatureCanvas from 'react-signature-canvas';
import { AutoSizer, Table, Column, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import LoadingSpinner from '../Loading-Component/Loading';
import { apiUrl } from '../../config.js';
import { useParams } from 'react-router';
import { useDeviceInfo } from '../DeviceInfo';
import ManageSecurity from './ManageSecurity';
// Stylesheets
import 'react-virtualized/styles.css';
import './managerReport.scss';
// Image Assets
import logo from '../../images/manager-report/stonegate-logo.svg';
import opusMainLogo from '../../images/manager-report/opus-logo.svg';
import opusLogos from '../../images/manager-report/footer-logos-v2.png';
import gigpig from '../../images/manager-report/gigpig.svg';
// React Icons
// https://react-icons.github.io/react-icons/icons/bs/
import { RiFocus3Line } from "react-icons/ri";
import { FaDownload } from "react-icons/fa";
import { FaLock } from "react-icons/fa";

import {
    BsCircleFill,
    BsExclamationTriangleFill,
    BsClockFill,
    BsCreditCardFill,
    BsArrowDownRightSquare,
    BsBarChartLineFill,
    BsArrowUpRightSquare,
    BsExclamationOctagonFill,
    BsFillFilterCircleFill,
    BsCalendarWeekFill,
    BsHandThumbsUp,
    BsArrowCounterclockwise,
    BsCheck2
} from 'react-icons/bs';
import { object } from 'prop-types';



export function ManagerReport() {
    const prevDuplicatedataRef = useRef();
    const [mangerreport, setmangerreport] = useState([]);
    const [DataAfterFilter, setDataAfterFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Percentagevals, setPecentagevals] = useState([]);
    const [filteredpercentages, setfilteredpercentages] = useState([]);
    const [ReponseMessage, setResponseMessage] = useState("");
    const [GraphData, setGrapData] = useState([]);
    const [clearAllFlag, setclearAllFlag] = useState(false);
    const { deviceInfo } = useDeviceInfo();
    const [showExportPopup, setShowExportPopup] = useState(false);
    const [settingsvalues, setsettingvalues] = useState([]);
    const [uniqueData, setUniqueData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showgigpigbutton, setshowgigpigbutton] = useState([]);
    const [summary, setSummary] = useState(0); // 0 = detailed, 1 = summarized

    const params = useParams();

    const [focusMode, setFocusMode] = useState(true);

    const toggleFocusMode = () => {
        setFocusMode(!focusMode);
    };
    

    async function GetPercentages() {
        if (deviceInfo !== '') {
            setLoading(true);
            try {
                var res = await fetch(`${apiUrl}/StonegateReport/GetPercentagevalues`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',

                    },
                    body: JSON.stringify({ Email: params.email, Guid: params.guid, Deviceinfo: deviceInfo, Summary: summary })
                });

                const response = await res.json();
                if (response.message != null) {
                    setLoading(false);
                    window.alert(response.item3);
                }
                else {
                    setPecentagevals(response.item1);
                    setGrapData(response.item2);
                }
            }
            catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    }
    const ReportNotice = () => {
        return (
            <div className="report-notice text-center text-white my-4">
                <BsExclamationOctagonFill />
                <h3>{ReponseMessage}</h3>

            </div>
        )
    }
    
    useEffect(() => {
        const controller = new AbortController();

        async function Getreporttable() {
            if (deviceInfo !== '') {
                setLoading(true);
                try {
                    var res = await fetch(`${apiUrl}/StonegateReport/GetMangerReport`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',

                        },
                        body: JSON.stringify({ Email: params.email, Guid: params.guid, Deviceinfo: deviceInfo, Summary: summary })
                    });

                    const result = await res.json();

                    if (result.message != null) {
                        setLoading(false);
                        window.alert(result.item2);

                    }
                    else if (result.item1[0].hasOwnProperty('response')) {
                        setResponseMessage(result.item1[0].response);
                        setLoading(false);


                    }
                    else {
                        setmangerreport(result.item1);
                        setDataAfterFilter(result.item1);
                        Getsettingvalues();
                        setLoading(false);
                       
                    }
                }
                catch (ex) {
                    console.log(ex);
                    setLoading(false);
                }

            }
        }

        document.title = "C247 + Stonegate Manager Report";
        async function fetchData() {
            try {
               
                 GetPercentages();
                 Getreporttable();
                
               
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        }
        fetchData();
       
        return () => controller.abort();
    }, [params, deviceInfo, summary]);

    async function Getsettingvalues() {

        try {
            setLoading(true);
            var res = await fetch(`${apiUrl}/StonegateReport/GetsettingsTableParams`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },

            });

            const result = await res.json();

            if (result.message != null) {
                setLoading(false);
                window.alert(result.item2);

            }
            else if (result.item1) {
                
                

                const paramsObject = result.item1.reduce((obj, item) => {
                    obj[item.parameterName] = item.parameterValue;
                    return obj;
                }, {});
                setsettingvalues(paramsObject);
                setLoading(false);
            }

        }
        catch (ex) {
            console.log(ex);
            setLoading(false);
        }

    }


    const formatethedate = (passeddate) => {
        if (!passeddate) {
            return passeddate;
        }
        else {
            const datePart = passeddate.split('T')[0];
            const [year, month, day] = datePart.split('-');
            const shortYear = year.substring(2); // Take only the last two digits of the year
            return `${day}/${month}/${shortYear}`;
        }
    }

    useEffect(() => {
        if (Percentagevals.length > 0) {
            setfilteredpercentages(
                Object.entries(Percentagevals[0]).reduce((acc, [key, value]) => {
                    if (!key.toLowerCase().includes("percent") && !key.toLowerCase().includes("wk")) {
                        acc[key] = value;
                    }
                    return acc;
                }, {})
            );
        }

    }, [Percentagevals]);

    //const [copyoffilters, setcopyoffilters] = useState(filteringcolums);
    const formatterfunc = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    });

    const CalulateNetCost = (data) => {
        const total = data.reduce((total, item) => total + Number(item.nettCost), 0);
        return Number(total.toFixed(2));
    };

    const CalulateTotalRecovered = (data) => {
        const totalNegative = data.reduce((total, item) => {
            const cost = Number(item.nettCost);
            return cost < 0 ? total + cost : total;
        }, 0);
        //const formatter = new Intl.NumberFormat('en-GB', {
        //    minimumFractionDigits: 2,
        //    maximumFractionDigits: 2,
        //    useGrouping: true,
        //});

        return formatterfunc.format(Math.abs(totalNegative));
        //if (totalNegative === 0) {
        //    return Number(totalNegative.toFixed(2));
        //}
        //else {
        //    return Number(totalNegative.toFixed(2) * -1); // Rounds the total negative cost to 2 decimal places and -1 to make it postive value
        //}

    };

    // Manage Security Modal with states
    const [showManagePopup, setShowManagePopup] = useState(false)
    const closeManageModal = () => setShowManagePopup(false);
    const openManageModal = () => setShowManagePopup(true);
    const ManageSecurityModal = () => {
        return (
            <>
                <Modal show={showManagePopup} onHide={closeManageModal} className="manage-security-modal" animation={true}>
                    <Modal.Header closeButton closeVariant="white">
                        <Modal.Title>Manage Security</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ManageSecurity managerReport={mangerreport} />
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <div className="padlock-key d-flex align-items-center">
                            <FaLock /><p className="ms-1 mb-0">Padlocked rows cannot be removed, only duplicated</p>
                        </div>
                        <Button variant="secondary" onClick={closeManageModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    const handleClose = () => setShowExportPopup(false);
    const Example = () => {

        return (
            <>
                <Modal show={showExportPopup} onHide={handleClose} animation={false}>
                    <Modal.Body>Your report is being collated for you and will be emailed within the next 10 minutes. Thank you for your patience.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    const handelexcelexport = async (event) => {

        setShowExportPopup(true);
        try {

            var res = await fetch(`${apiUrl}/StonegateReport/ExportToCSV`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({ Email: params.email, Guid: params.guid, Deviceinfo: deviceInfo, export: true })
            });

            const response = await res.json();
            if (response.message != null) {

                window.alert(response.item2);

            }
            else {

                //window.location.href = response.item1[0].response; // commented out because the export sp handles to send an email with attachment
                setLoading(false);
            }
        }
        catch (ex) {
            console.log(ex);
            setLoading(false);
        }

    }

    const buildLink = (site) => {
        const encodedEmail = site.email;
        const encodedBun = site.bun;
        const build_link = `${site.baseurl}e_value=${encodedEmail}&opus_bun=${encodedBun}&t_value=${site.token}`;
        return build_link;

    };
    const handleGigpigPortal = async () => {

        const extractedData = mangerreport.map(item => ({
            site: item.site,
            bun: item.bun,
            baseurl: settingsvalues.GigPigBaseUrl,
            email: item.hashedSiteEmail,
            token: params.guid
        }));

        const unique = Array.from(new Set(extractedData.map(item => JSON.stringify(item))))
            .map(item => JSON.parse(item));
        setUniqueData(unique);
     
        if (unique.length > 1) {
            setShowModal(true); // Open the modal if there are multiple unique sites
        } else if (unique.length === 1) {
            let openurl = buildLink(unique[0])
            const newWindow = window.open(openurl, "_blank");
            if (newWindow !== null) {
                await logGigpigAccess();
            }
        }

    }
    const RmSiteListModal = () => {

        const [searchTerm, setSearchTerm] = useState("");
        const handleSiteSelect = async (site) => {
            let openurl = buildLink(site)
            const newWindow = window.open(openurl, "_blank")
            setShowModal(false);
            if (newWindow !== null) {
                await logGigpigAccess();
            }
        };
        const handelSearchInputChange = (event) => {
            setSearchTerm(event.target.value);
        };

        const SearchedListofsites = uniqueData && uniqueData.length > 0
            ? uniqueData.filter((options) => {
                if (options.site && options.site.trim() !== '') {

                    return options.site.toLowerCase().includes(searchTerm.toLowerCase())
                }
                return false;
            }

            )
            : [];

        return (
            <>
                <Modal className="gigpigmodal justify-content-center" show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton closeVariant='white'>
                        <Modal.Title>Select a Site</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="GipPig-Modal">
                        <div className="search-bar-container">
                            <input
                                type="text"
                                placeholder="Search for site..."
                                value={searchTerm}
                                className="search-input"
                                onChange={handelSearchInputChange}
                            />
                        </div>
                        <div className="list-container">
                            <ListGroup style={{ width: '100%' }}>
                                {SearchedListofsites.map((site, index) => (
                                    <ListGroup.Item key={index} action onClick={() => handleSiteSelect(site)}>
                                        {site.site}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </Modal.Body>
                </Modal>



            </>
        );
    }
    const logGigpigAccess = async () => {
        await fetch(`${apiUrl}/StonegateReport/LogGigPigAccess`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Email: params.email, Guid: params.guid, Metadata: deviceInfo })
        });
    }
    useEffect(() => {
        setshowgigpigbutton(Array.from(new Set(mangerreport.map(item => item.gigPigLive))))
    }, [mangerreport])

    


    function formatTime(timeString) {
        if (timeString === null) return "null";
        if (!timeString) return "";
        return timeString.slice(0, 5);
    }
    const Bannerdata = () => {

        const [copyofDatafiltered, setcopyofDatafiltered] = useState(DataAfterFilter);

        const Symbolandvalue = ({ Dept }) => {

            if (Dept === 'Security') {
                return (
                    <div className="d-flex align-items-center">
                        <label className="mb-0" style={{ color: filteredpercentages.securityIncrease > 0 ? 'red' : 'green' }}>&pound;{formatterfunc.format(Number(filteredpercentages.securityIncrease).toFixed(2))}</label>
                        <span>{filteredpercentages.securityIncrease > 0 ? <BsArrowUpRightSquare fill="red" /> : <BsArrowDownRightSquare fill="green" />}</span>
                    </div>
                )
            }
            if (Dept === 'Cleaning') {
                return (
                    <div className="d-flex align-items-center">
                        <label className="mb-0" style={{ color: filteredpercentages.cleaningIncrease > 0 ? 'red' : 'green' }}>&pound;{formatterfunc.format(Number(filteredpercentages.cleaningIncrease).toFixed(2))}</label>
                        <span>{filteredpercentages.cleaningIncrease > 0 ? <BsArrowUpRightSquare fill="red" /> : <BsArrowDownRightSquare fill="green" />}</span>
                    </div>
                )
            }
            if (Dept === 'Facilities Management') {
                return (
                    <div className="d-flex align-items-center">
                        <label className="mb-0" style={{ color: filteredpercentages.fmIncrease > 0 ? 'red' : 'green' }}>&pound;{formatterfunc.format(Number(filteredpercentages.fmIncrease).toFixed(2))}</label>
                        <span>{filteredpercentages.fmIncrease > 0 ? <BsArrowUpRightSquare fill="red" /> : <BsArrowDownRightSquare fill="green" />}</span>
                    </div>
                )
            }
            if (Dept === 'Entertainment') {
                return (
                    <div className="d-flex align-items-center">
                        <label className="mb-0" style={{ color: filteredpercentages.entertainmentIncrease > 0 ? 'red' : 'green' }}>&pound;{formatterfunc.format(Number(filteredpercentages.entertainmentIncrease).toFixed(2))}</label>
                        <span>{filteredpercentages.entertainmentIncrease > 0 ? <BsArrowUpRightSquare fill="red" /> : <BsArrowDownRightSquare fill="green" />}</span>
                    </div>
                )
            }


        }


        if (copyofDatafiltered.length > 0) {
            return (
                <>
                    <div className="d-md-flex flex-wrap align-items-center justify-content-between w-100">
                        <div className="date statistics-bar__item d-flex align-items-center">
                            <label className="mb-0 me-2">Nett:</label>
                            <label className="mb-0">&pound;{CalulateNetCost(copyofDatafiltered).toLocaleString(undefined, { maximumFractionDigits: 2 })}</label>
                        </div>
                        <div className="date statistics-bar__item d-flex align-items-center">
                            <label className="mb-0 me-2">Rec'd:</label>
                            <label className={`mb-0 ${CalulateTotalRecovered(copyofDatafiltered) > '0' ? 'text-success' : 'text-Danger'}`}>&pound;{CalulateTotalRecovered(copyofDatafiltered).toLocaleString(undefined, { maximumFractionDigits: 2 })}</label>
                        </div>
                        <div className="statistic d-flex flex-wrap align-content-start align-items-center me-3">
                            <label className="me-2">Sec:</label>
                            <Symbolandvalue Dept='Security' />
                        </div>
                        <div className="statistic d-flex  align-content-start align-items-center me-3">
                            <label className="me-2">Cleaning:</label>
                            <Symbolandvalue Dept='Cleaning' />
                        </div>

                        <div className="statistic d-flex align-content-start align-items-center me-3">
                            <label className="me-2">FM:</label>
                            <Symbolandvalue Dept='Facilities Management' />
                        </div>
                        <div className="statistic d-flex align-content-start align-items-center me-3">
                            <label className="me-2">Ents:</label>
                            <Symbolandvalue Dept='Entertainment' />
                        </div>
                    </div>
                </>
            )
        }
    }



    // Status icon component for first column of table
    const getIconBasedOnCat = (cat) => {
        switch (cat) {
            case "New":
                return <BsCircleFill color="blue" />;
            case "Credited":
                return <BsCreditCardFill color="green" />;
            case "Late":
                return <BsClockFill color="orange" />;
            case "Missing":
                return <BsExclamationTriangleFill color="red" />;
            case "Future Booking":
                return <BsCalendarWeekFill color="black" />;
            default:
                return ""; // Handle the case where the cat value doesn't match any of the expected values
        }
    };

    const handleSubmit = async (Rating, LineId, Rl, Feedback) => {
        let EmailAddress = params.email
        const data = {
            Rating,
            Feedback,
            LineId,
            Rl,
            EmailAddress
        };
        try {
            var res = await fetch(`${apiUrl}/StonegateReport/FeedbackFromReport`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(data)
            });

            console.log(res);
            if (!res.ok) {
                const response = await res.json();
                setLoading(false);
                window.alert(response.item3);


            }
            else if (res.ok) {
                setLoading(false);

            }

        }
        catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    }

    // This component is for the desktop table verion (duplicated code incase it gets reversed)
    const ReportTableRow = () => {
        const [showRatingFeedback, setShowRatingFeedback] = useState(false);
        const [Duplicatedata, setduplicatedata] = useState(DataAfterFilter);
        const [feedbackdata, setfeedbackdata] = useState({ rating: null, lineid: null, rl: null })
        const [signOffRowData, setsignOffRowData] = useState([]);

        const [showsignOffModal, setshowsignOffModal] = useState(false);

        const ShowFeedbackmodel = (rating, lineId, Rl) => {
            setShowRatingFeedback(true);
            setfeedbackdata(prevState => ({
                ...prevState,
                rating: rating,
                lineid: lineId,
                rl: Rl
            }));

        }
        const ratingModalHide = () => setShowRatingFeedback(false);
        //The reason why we are doing this is not loose the state of ratings and requiredsignoff already exists in duplicate data.
        useEffect(() => {
            if (!Duplicatedata.length) {
                setduplicatedata(DataAfterFilter);
            } else {
                const previousData = prevDuplicatedataRef.current || [];
                const updatedData = DataAfterFilter.map(dataItem => {
                    const existingItem = previousData.find(dd => dd.rw === dataItem.rw); // read from refence when the component mounts
                    return existingItem || dataItem; // If an existing item was modified, preserve it; otherwise, use the new data item
                });
                setduplicatedata(updatedData);
            }
        }, [DataAfterFilter]);

        //Calling Managersignoffmodal

        function handelManagerSignoffmodal(data) {
            setshowsignOffModal(true);
            setsignOffRowData(data);
        }
        const signoffModalHide = () => setshowsignOffModal(false);

        //Individual function to render
        const ratingChanged = (newRating, lineId, rl) => {
            if (lineId !== 0) {
                setduplicatedata(prevData => {
                    const newData = prevData.map(item => {
                        if (item.lineId === lineId) {
                            return { ...item, rating: newRating };
                        }
                        return item;
                    });

                    // After mapping through prevData to update the rating,
                    //Storing the data into new set of refernce when ever the rating is updated.
                    prevDuplicatedataRef.current = newData;

                    return newData; // Return the new data to update duplicatedata
                });




                if (newRating === 1 || newRating === 5) {
                    ShowFeedbackmodel(newRating, lineId, rl);
                } else if (newRating >= 2 && newRating <= 4) {
                    handleSubmit(newRating, lineId, rl, null);
                }
            }
        };
        const CustomTooltip = ({ children, content }) => {
            const [showTooltip, setShowTooltip] = useState(false);

            return (
                <div
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                    style={{ position: 'relative', display: 'inline-block' }}
                >
                    {children}
                    {showTooltip && (
                        <div className="custom-tooltip show">
                            {content}
                        </div>
                    )}
                </div>
            );
        };

        //Remove thumb button from row item once the item has been update

        const handelupdatinglineitem = (lineid,ratings,signatures) => {
            if (lineid !== 0) {
                setduplicatedata(prevData => {
                    const newData = prevData.map(item => {
                        if (item.lineId === lineid) {
                            return { ...item, requireApproval: false, rating: ratings, managerSignature: signatures };
                        }
                        return item;
                    });

                    // After mapping through prevData to update the reqired for approval,
                    //Storing the data into new set of refernce when ever the reqired for approval is updated.
                    prevDuplicatedataRef.current = newData;

                    return newData; // Return the new data to update duplicatedata
                });


            }
        }


        //Individual columns to render
        const categoryCellRenderer = ({ rowData }) => (

            <CustomTooltip content={rowData.cat}>
                {getIconBasedOnCat(rowData.cat)}
            </CustomTooltip>
        );

        // Cell renderer for the rating column using ReactStars
        const ratingCellRenderer = ({ rowData }) => {
            if (rowData.lineId !== 0) {
                return (
                    <ReactStars
                        count={5}
                        onChange={(newRating) => ratingChanged(newRating, rowData.lineId, rowData.rl)}
                        value={rowData.rating}
                        size={17}
                        half={false}
                        color2="#e23193"
                        color1="#999"
                    />
                );
            }
            return null; // Render nothing if lineId is 0
        };

        const rowHighlightStyle = (index) => {
            if (Duplicatedata.length > 0 && index >= 0) {
                const rowData = Duplicatedata[index];
                if (rowData.rowHighlightColour) {
                    return { backgroundColor: rowData.rowHighlightColour };
                } else if (rowData.credited) {
                    return { backgroundColor: '#ddd', textDecoration: 'line-through' };
                } else {
                    return { backgroundColor: 'white' };
                }
            }
        }
        
        // General purpose cell renderer for applying conditional styles and formatting dates
        const dateCellRenderer = ({ cellData, rowData }) => {
            return <div>{formatethedate(cellData)}</div>;
        };

        // Cell renderer for start and finish times
        const timeCellRenderer = ({ cellData, rowData }) => {
            return <div>{formatTime(cellData)}</div>;
        };

        // Cell renderer for the nett cost column
        const nettCostCellRenderer = ({ cellData, rowData }) => {
            return <div> &pound; {cellData.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>;
        };
        // Cell renderer for the challenge button
        const challengeCellRenderer = ({ rowData }) => {
            if (rowData.underQuery != null) {
                return (
                    <span style={{ color: rowData.underQueryColour }}>
                        <strong>{rowData.underQuery}</strong>
                    </span>
                );
            } else if (rowData.challengeLink) {
                return (
                    <button className="btn btn-primary" onClick={() => window.open(rowData.challengeLink, '_blank')}>
                        Challenge
                    </button>
                );
            } else if (rowData.credited) {
                return (
                    <div>
                        <div>Credited</div>
                        <div>{formatethedate(rowData.credited)}</div>
                    </div>
                );
            } else {
                return null
            }
        };
        //Gigpig Cell render

        const gigPigCellRender = ({ rowData }) => {
            if (rowData.gigPig === true) {
                return <img style={{ height: 'auto', width: '100%' }} src={gigpig} />
            }
            if (rowData.requireApproval === true) {
                return <button className="btn btn-primary d-flex align-items-center justify-content-center px-3" title="Signoff Charge" onClick={() => handelManagerSignoffmodal(rowData)}>Approve</button>
            }
        }

        // Cell renderer for displaying notes
        const notesCellRenderer = ({ rowData }) => (
            <div title={rowData.notes}>
                <p>{rowData.notes}</p>
            </div>
        );

        const defaultCellRenderer = ({ cellData, rowData }) => {
            return <div title={cellData}>{cellData}</div>;
        };

        // cell renderer that combines both defaultCellRenderer and dateCellRenderer
        const combinedCellRenderer = ({ cellData, rowData }) => {
            return (
                <div>
                    <div>{cellData ? formatethedate(cellData) : ""}</div>
                    <div title={rowData.period}>{rowData.period}</div>                   
                </div>
            );
        };

        // Gets the viewport width and saves to a variabe if a certain size
        const widthOfDiv = getWidthOfClass('report-table-container');

        const ManagerSigRenderer = ({ rowData }) => {
            const image = rowData.managerSignature;
            // Check if the image data exists
            if (image) {
                // Construct the data URL
                const dataUrl = image;
                // Return the image component with the data URL as the source
                return <img className="w-100" src={dataUrl} alt="Manager's Signature" />;
            } else {
                // Return null if there is no image data
                return null;
            }
        };

        function getWidthOfClass(className) {
            const elements = document.getElementsByClassName(className);
            if (elements.length > 0) {
                const div = elements[0]; // Assuming you want the first element with the class
                const rect = div.getBoundingClientRect();
                if (rect.width > 1920) {
                    return rect.width;
                } else {
                    return 2320;
                }
            } else {
                // Handle case where no elements with the class are found
                return 0;
            }
        }

        return (
           <>
                <RatingModal showModal={showRatingFeedback} onHide={ratingModalHide} lineid={feedbackdata.lineid} onSubmit={handleSubmit} rating={feedbackdata.rating} rl={feedbackdata.rl} />
                <SignoffModal showModal={showsignOffModal} onHide={signoffModalHide} rowdata={signOffRowData} emailAddress={params.email} setLoading={setLoading} deviceinfo={deviceInfo} settingsvalues={settingsvalues} Updatelineitem={handelupdatinglineitem} />
                <AutoSizer>
                    {({ height }) => (
                        <Table
                            width={widthOfDiv}
                            horizontal={true}
                            scrollingResetTimeInterval={0}
                            height={height}
                            rowHeight={60}
                            rowRender={50}
                            rowCount={Duplicatedata.length}
                            rowGetter={({ index }) => Duplicatedata[index]}
                            rowStyle={({ index }) => rowHighlightStyle(index)}
                            rowClassName={"mangerTableRow"}
                        >
                            <Column width={45} minWidth={45} maxWidth={45} flexGrow={1} flexShrink={1} label="" className={"category-column"} dataKey="cat" cellRenderer={categoryCellRenderer} />
                            <Column width={60} minWidth={60} flexGrow={1} label="Received" dataKey="invoiced" cellRenderer={dateCellRenderer} />
                            <Column width={100} minWidth={100} flexGrow={1} label="Reported" dataKey="reportedToFinance" cellRenderer={combinedCellRenderer} />
                            {/* <Column width={130} minWidth={130} flexGrow={1} label="Period" dataKey="period" cellRenderer={defaultCellRenderer} />*/}
                            {/* <Column width={80} minWidth={80} flexGrow={1} label="Week" dataKey="weekending" cellRenderer={dateCellRenderer} />*/}
                            <Column width={60} minWidth={60} flexGrow={1} label="Date" dataKey="jobdate" cellRenderer={dateCellRenderer} />
                            <Column width={130} minWidth={130} flexGrow={1} label="Department" dataKey="department" cellRenderer={defaultCellRenderer} />
                            <Column width={130} minWidth={130} label="Site" dataKey="site" cellRenderer={defaultCellRenderer} style={{ whiteSpace: 'nowrap', textWrap: 'wrap' }} />
                            <Column width={130} minWidth={130} flexGrow={1} className={"elilipses-non-paragraph"} label="Provider" dataKey="provider" cellRenderer={defaultCellRenderer} />
                            <Column width={80} minWidth={80} flexGrow={1} className={"role-column"} label="Role" dataKey="role" cellRenderer={defaultCellRenderer} style={{ whiteSpace: 'nowrap', textWrap: 'wrap' }} />
                            <Column width={120} maxWidth={120} flexGrow={1} cellRenderer={gigPigCellRender} dataKey="gigpig" />
                            <Column width={125} minWidth={125} flexGrow={1} label="Challenge" dataKey="challengeLink" cellRenderer={challengeCellRenderer} />
                            <Column width={50} minWidth={50} flexGrow={1} label="Hours" dataKey="hours" cellRenderer={defaultCellRenderer} />
                            <Column width={80} minWidth={80} flexGrow={1} label="Net Cost" dataKey="nettCost" cellRenderer={nettCostCellRenderer} />
                            <Column width={80} minWidth={80} flexGrow={1} label="Start" dataKey="start" cellRenderer={timeCellRenderer} />
                            <Column width={80} minWidth={80} flexGrow={1} label="Finish" dataKey="finish" cellRenderer={timeCellRenderer} />
                            <Column width={125} minWidth={125} flexGrow={1} label="Manager Signature" dataKey="managerSig" cellRenderer={ManagerSigRenderer} />
                            <Column width={300} minWidth={300} flexGrow={1} className={"elilipses"} label="Notes" dataKey="notes" cellRenderer={notesCellRenderer} />
                            <Column width={100} minWidth={100} flexGrow={1} label="Rating" dataKey="rating" cellRenderer={ratingCellRenderer} />
                            <Column width={80} minWidth={80} flexGrow={1} label="BUN" dataKey="bun" cellRenderer={defaultCellRenderer} />
                    {/*        <Column width={150} minWidth={150} flexGrow={1} label="Credited" dataKey="credited" cellRenderer={dateCellRenderer} />*/}
                        </Table>
                    )}
                </AutoSizer>
            </>
        )
    }

    // Changes the table to a card version for only showing on mobile devices
    const ReportTableCard = () => {
        const [showRatingFeedback, setShowRatingFeedback] = useState(false);
        const [Duplicatedata, setduplicatedata] = useState(DataAfterFilter);
        const [feedbackdata, setfeedbackdata] = useState({ rating: null, lineid: null, rl: null })
        const [signOffRowData, setsignOffRowData] = useState([]);
        const [showsignOffModal, setshowsignOffModal] = useState(false);
        const ratingModalHide = () => setShowRatingFeedback(false);
        const ShowFeedbackmodel = (rating, lineId, Rl) => {
            setShowRatingFeedback(true);
            setfeedbackdata(prevState => ({
                ...prevState,
                rating: rating,
                lineid: lineId,
                rl: Rl
            }));

        }

        // Gets the viewport width and saves to a variabe if a certain size
        const widthOfDiv = getWidthOfClass('report-table-container');

        function getWidthOfClass(className) {
            const elements = document.getElementsByClassName(className);
            if (elements.length > 0) {
                const div = elements[0]; // Assuming you want the first element with the class
                const rect = div.getBoundingClientRect();
                if (rect.width > 1920 || rect.width < 569) {
                    return rect.width;
                } else {
                    return 2320;
                }
            } else {
                // Handle case where no elements with the class are found
                return 0;
            }
        }

        //The reason why we are doing this is not loose the state of ratings and requiredsignoff already exists in duplicate data.
        useEffect(() => {
            if (!Duplicatedata.length) {
                setduplicatedata(DataAfterFilter);
            } else {
                const previousData = prevDuplicatedataRef.current || [];
                const updatedData = DataAfterFilter.map(dataItem => {
                    const existingItem = previousData.find(dd => dd.rw === dataItem.rw); // read from refence when the component mounts
                    return existingItem || dataItem; // If an existing item was modified, preserve it; otherwise, use the new data item
                });
                setduplicatedata(updatedData);
            }
        }, [DataAfterFilter]);

        //Individual function to render
        
        const ratingChanged = (newRating, lineId, rl) => {
            if (lineId !== 0) {
                setduplicatedata(prevData => {
                    const newData = prevData.map(item => {
                        if (item.lineId === lineId) {
                            return { ...item, rating: newRating };
                        }
                        return item;
                    });

                    // After mapping through prevData to update the rating,
                    //Storing the data into new set of refernce when ever the rating is updated.
                    prevDuplicatedataRef.current = newData; 

                    return newData; // Return the new data to update duplicatedata
                });

              


                if (newRating === 1 || newRating === 5) {
                    ShowFeedbackmodel(newRating, lineId, rl);
                } else if (newRating >= 2 && newRating <= 4) {
                    handleSubmit(newRating, lineId, rl, null);
                }
            }
        };
        const CustomTooltip = ({ children, content }) => {
            const [showTooltip, setShowTooltip] = useState(false);

            return (
                <div
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                    style={{ position: 'relative', display: 'inline-block' }}
                >
                    {children}
                    {showTooltip && (
                        <div className="custom-tooltip show">
                            {content}
                        </div>
                    )}
                </div>
            );
        };

        //Individual columns to render
        const categoryCellRenderer = ({ rowData }) => (
            <CustomTooltip content={rowData.cat}>
                {getIconBasedOnCat(rowData.cat)}
            </CustomTooltip>
        );
        //Gigpig Icon or manager signoff


        function handelManagerSignoffmodal(data) {
            setshowsignOffModal(true);
            setsignOffRowData(data);
        }
        const signoffModalHide = () => setshowsignOffModal(false);
        const gigPigCellRender = ({ rowData }) => {
            if (rowData.gigPig === true) {
                return <img style={{ height: 'auto', width: '100%' }} src={gigpig} />
            }
            if (rowData.requireApproval === true) {
                return <button className="btn btn-primary d-flex align-items-center justify-content-center px-3" title="Signoff Charge" onClick={() => handelManagerSignoffmodal(rowData)}>Approve</button>
            }
        }


        //Remove thumb button from row item once the item has been update

        const handelupdatinglineitem = (lineid, ratings, signatures) => {
            if (lineid !== 0) {
                setduplicatedata(prevData => {
                    const newData = prevData.map(item => {
                        if (item.lineId === lineid) {
                            return { ...item, requireApproval: false, rating: ratings, managerSignature: signatures };
                        }
                        return item;
                    });

                    // After mapping through prevData to update the reqired for approval,
                    //Storing the data into new set of refernce when ever the reqired for approval is updated.
                    prevDuplicatedataRef.current = newData;

                    return newData; // Return the new data to update duplicatedata
                });


            }
        }

        // Cell renderer for the rating column using ReactStars
        const ratingCellRenderer = ({ rowData }) => {
            if (rowData.lineId !== 0) {
                return (
                    <ReactStars
                        count={5}
                        onChange={(newRating) => ratingChanged(newRating, rowData.lineId, rowData.rl)}
                        value={rowData.rating}
                        size={17}
                        half={false}
                        color2="#e23193"
                        color1="#999"
                    />
                );
            }
            return null; // Render nothing if lineId is 0
        };

        const rowHighlightStyle = (index) => {
            if (Duplicatedata.length > 0 && index >= 0) {
                const rowData = Duplicatedata[index];
                if (rowData.rowHighlightColour) {
                    return { backgroundColor: rowData.rowHighlightColour };
                } else if (rowData.credited) {
                    return { backgroundColor: '#ddd', textDecoration: 'line-through' };
                } else {
                    return { backgroundColor: 'white' };
                }
            }   
        }

        //Used for calculating the height of a card based on its content
        const cache = new CellMeasurerCache({
            defaultHeight: 410,
            fixedWidth: true
        });

        // rowRenderer is for the mobile version of the table which displays on mobile only
        function rowRenderer({
            key, // Unique key within array of rows
            index, // Index of row within collection
            style,
            parent,
        }) {
            const rowData = Duplicatedata[index]; // Get the specific object for the current row
            const highlightStyle = rowHighlightStyle(index);
            const categoryIcon = categoryCellRenderer({ rowData });
            const rating = ratingCellRenderer({ rowData });
            const gigPig = gigPigCellRender({ rowData });
            // Check if rowData exists and it has the 'site' property
            if (!rowData) {
                return null; // Return null if rowData is undefined or doesn't have the 'site' property
            }

            return (


                <CellMeasurer
                    cache={cache}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                >
                    {({ measure, registerChild }) => (
                        <div className="mobile-card" key={key} style={style} ref={registerChild}>
                            <div className="mobile-card-inner" style={{ ...highlightStyle }} onLoad={measure}>

                                <div className="d-flex align-items-center justify-content-between mobile-row header-row py-3">
                                    <div className="ms-1">
                                        <h5 className="mb-1">Site:<strong> {rowData.site}</strong></h5>
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0"><strong>Received:</strong> {formatethedate(rowData.invoiced)}</p>
                                            <span className="mx-1">|</span>
                                            <p className="mb-0"><strong>BUN:</strong> {rowData.bun}</p>
                                        </div>
                                    </div>
                                    <div className="ps-2">{categoryIcon}</div>
                                </div>

                                <div className="d-flex justify-content-between mobile-row">
                                    <div className="mobile-col">
                                        <p><strong>Reported to Finance:</strong></p>
                                        <p>{formatethedate(rowData.reportedToFinance)}</p>
                                    </div>
                                    <div className="mobile-col">
                                        <p><strong>Job Date:</strong></p>
                                        <p>{formatethedate(rowData.jobdate)}</p>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mobile-row">
                                    <div className="mobile-col">
                                        <p><strong>Credited:</strong></p>
                                        <p>{formatethedate(rowData.credited)}</p>
                                    </div>
                                    <div className="mobile-col">
                                        <p><strong>Provider:</strong></p>
                                        <p>{rowData.provider}</p>
                                    </div>

                                </div>

                                <div className="d-flex justify-content-between mobile-row">
                                    <div className="mobile-col">
                                        <p><strong>Department:</strong></p>
                                        <p>{rowData.department}</p>
                                    </div>
                                    <div className="mobile-col">
                                        <p><strong>Role:</strong></p>
                                        <p>{rowData.role}</p>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mobile-row">
                                    <div className="mobile-col">
                                        <p><strong>Start:</strong> {formatTime(rowData.start)}</p>
                                    </div>
                                    <div className="mobile-col">
                                        <p><strong>Finish:</strong> {formatTime(rowData.finish)}</p>
                                    </div>
                                    <div className="mobile-col">
                                        <p><strong>Hours:</strong> {rowData.hours}</p>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mobile-row">
                                    <div className="mobile-col">
                                        <p><strong>Net Cost:</strong></p>
                                        <p>&pound;{rowData.nettCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                                    </div>
                                    <div className="text-center mobile-col">
                                        {rating ? (
                                            <>
                                                <p className="text-start"><strong>Rating:</strong></p>
                                                {rating}
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="mobile-col d-flex align-items-center justify-content-center">
                                        {gigPig}
                                    </div>
                                </div>

                                {rowData.notes ? (
                                    <div className="mobile-row p-2">
                                        <p>{rowData.notes}</p>
                                    </div>
                                ) : null}

                                <div className="d-flex justify-content-between align-items-center mobile-row p-2">
                                    <div className="text-center mobile-col border-0">
                                        {rowData.challengeLink ? (
                                            <div>
                                                <button className="btn btn-primary w-100" onClick={() => window.open(rowData.challengeLink, '_blank')}>
                                                    Challenge
                                                </button>
                                            </div>
                                        ) : null}
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                </CellMeasurer>

            );
        }
        return (
            <>
                <RatingModal showModal={showRatingFeedback} onHide={ratingModalHide} lineid={feedbackdata.lineid} onSubmit={handleSubmit} rating={feedbackdata.rating} rl={feedbackdata.rl} />
                <SignoffModal showModal={showsignOffModal} onHide={signoffModalHide} rowdata={signOffRowData} emailAddress={params.email} setLoading={setLoading} deviceinfo={deviceInfo} settingsvalues={settingsvalues} Updatelineitem={handelupdatinglineitem} />
                <AutoSizer>
                    {({ height }) => (
                        <List
                            width={widthOfDiv}
                            height={height}
                            rowCount={Duplicatedata.length}
                            deferredMeasurementCache={cache}
                            rowHeight={cache.rowHeight}
                            rowRenderer={rowRenderer}
                        />
                    )}
                </AutoSizer>
            </>
         
        );

    };
    
    const handeldatachange = async (data, restflag) => {
        if (restflag) {
            setclearAllFlag(true);
            setDataAfterFilter(data);

        }
        else {
            setDataAfterFilter(data);
        }
    }

    useEffect(() => {
        if (JSON.stringify(DataAfterFilter) === JSON.stringify(mangerreport)) {
            if (clearAllFlag) {
                setLoading(false);
            }
        }
    }, [DataAfterFilter]) // Onclear to render the loader

    // Controls the states for the stats and filter to collapse when on mobile vs desktop
    const [mobileFilters, setMobileFilters] = useState(false);
    const [mobileStats, setMobileStats] = useState(false);

    const toggleMenu = (menuType) => {
        if (menuType === 'filters') {
            setMobileFilters(!mobileFilters);
            setMobileStats(false);
        } else if (menuType === 'stats') {
            setMobileStats(!mobileStats);
            setMobileFilters(false);
        }
    }

    const [showManageSecButton, setShowManageSecButton] = useState([]);
    const shouldShowButton = showManageSecButton.every(button => button === true);

    useEffect(() => {
        if (mangerreport.length > 0) {
            const uniqueButtons = Array.from(new Set(mangerreport.map(item => item.showSecurityButton)));
            setShowManageSecButton(uniqueButtons);
        }            
    }, [mangerreport])


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) { // Change 768 to your desired breakpoint
                setMobileFilters(true);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    const toggleSummary = () => {
        setSummary(prev => (prev === 0 ? 1 : 0));
    };

    return (
        <div className="stonegate-container">

            <LoadingSpinner loading={loading} />
            <Example />
            <RmSiteListModal />
            <ManageSecurityModal />
            <div className={`${focusMode ? 'd-block' : 'd-none' } report-header w-100 p-4 pt-4 pb-2 mb-2`}>
                <Row>
                    <Col md={12}>
                        <div className="text-light w-100 d-flex align-items-top justify-content-between">
                            <div className="header-logos me-4">
                                <div>
                                    <img src={logo} alt="Stonegate logo" />
                                    <h4>Costings</h4>
                                </div>
                            </div>

                            <div className="button-container-gopi d-flex">
                                {/*<Button style={{ height: '45px', padding: '0px 15px' }} variant="primary apply-button d-flex align-items-center export-button me-3" onClick={toggleFocusMode}>*/}
                                {/*    <RiFocus3Line /> */}
                                {/*    <span className="ms-1 d-none d-lg-block">Focus Mode</span>*/}
                                {/*</Button>*/}
                                <div style={{ marginRight: '1em' }}>
                                    <Button style={{ height: '45px', padding: '0px 15px' }} variant="primary apply-button d-block export-button" onClick={(e) => { handelexcelexport(e); }} >
                                        <FaDownload color="white" />
                                    </Button>
                                </div>
                                { /* Show manage sec button if value === 1 */ }
                                {shouldShowButton && (
                                    <div style={{ marginRight: '1em' }}>
                                        <button className="btn btn-primary gigpig-btn d-flex" onClick={openManageModal}>
                                            <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 0C10.3664 0 8.47222 0.430625 6.68972 0.91C4.86615 1.3975 3.02779 1.97437 1.94679 2.32375C1.49483 2.47138 1.09414 2.74213 0.790743 3.10489C0.487351 3.46765 0.293566 3.9077 0.231652 4.3745C-0.747491 11.6496 1.52458 17.0414 4.28129 20.6083C5.45032 22.1341 6.84419 23.4777 8.41636 24.5944C9.0505 25.038 9.63864 25.3776 10.1381 25.61C10.5981 25.8245 11.0926 26 11.5 26C11.9074 26 12.4003 25.8245 12.8619 25.61C13.464 25.3205 14.04 24.9808 14.5836 24.5944C16.1558 23.4778 17.5497 22.1341 18.7187 20.6083C21.4754 17.0414 23.7475 11.6496 22.7683 4.3745C22.7065 3.90748 22.5128 3.46717 22.2094 3.10413C21.906 2.74109 21.5053 2.47005 21.0532 2.32213C19.484 1.8132 17.9025 1.34181 16.3103 0.908375C14.5278 0.43225 12.6336 0 11.5 0ZM11.5 8.125C12.082 8.12414 12.6455 8.32704 13.0907 8.69775C13.5359 9.06846 13.8341 9.58307 13.9325 10.1504C14.0309 10.7178 13.9231 11.3013 13.6282 11.7975C13.3333 12.2938 12.8704 12.6709 12.3214 12.8619L12.9539 16.0956C12.9769 16.2132 12.9734 16.3344 12.9435 16.4505C12.9136 16.5666 12.8581 16.6747 12.781 16.7671C12.7039 16.8595 12.6071 16.9339 12.4976 16.985C12.388 17.0361 12.2684 17.0625 12.1473 17.0625H10.8527C10.7317 17.0623 10.6123 17.0356 10.5029 16.9845C10.3936 16.9333 10.297 16.8589 10.2201 16.7665C10.1432 16.6741 10.0879 16.5661 10.0581 16.4501C10.0283 16.3342 10.0247 16.2131 10.0477 16.0956L10.6786 12.8619C10.1296 12.6709 9.66666 12.2938 9.37179 11.7975C9.07692 11.3013 8.96913 10.7178 9.06752 10.1504C9.1659 9.58307 9.46411 9.06846 9.90933 8.69775C10.3545 8.32704 10.918 8.12414 11.5 8.125V8.125Z" fill="white" />
                                            </svg>
                                            <span className="ms-3 d-none d-md-block">Manage Security</span>
                                        </button>
                                    </div>
                                )}
                                {showgigpigbutton && showgigpigbutton[0] === true && <button style={{ marginRight: '1em' }} className="btn btn-primary gigpig-btn d-flex
" onClick={() => handleGigpigPortal()}>
                                    <svg width="25" height="26" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.07018 18.6059C3.16548 18.6059 0 21.156 0 24.3031C0 27.4501 3.16548 30.0002 7.07018 30.0002C10.9749 30.0002 14.1404 27.4493 14.1404 24.3031C14.1404 21.1568 10.9749 18.6059 7.07018 18.6059ZM4.71447 25.9758C3.54582 25.9758 3.55503 22.7553 4.71447 22.7553C5.87392 22.7553 5.87392 25.9758 4.71447 25.9758ZM9.39598 25.9688C8.22733 25.9688 8.23653 22.7483 9.39598 22.7483C10.5554 22.7483 10.5554 25.9688 9.39598 25.9688Z" fill="white" />
                                        <path d="M14.1404 1.45734H12.9403V24.3023H14.1404V1.45734Z" fill="white" />
                                        <path d="M16.9691 7.38422C19.0404 9.34682 22.3239 12.5392 23.955 12.9353C24.4856 13.0642 24.5286 12.8798 24.7126 12.6954C25.1926 12.2126 25.563 11.4508 25.9181 10.8C26.339 10.0289 26.6197 9.20072 27.0407 8.43037C27.3796 7.31859 27.8482 6.49433 28.0882 5.85758C28.4172 4.98488 28.7201 4.08874 28.9808 3.14963C29.0368 2.94727 28.9693 2.64726 28.819 2.49647C27.7209 1.39094 25.2962 0.853413 25.1811 0.79716C23.0194 0.233069 20.9329 0.0885298 18.8302 0.000243929C17.6823 -0.00522511 16.4124 0.0947801 15.5689 0.258851C15.1939 0.311979 14.8243 0.362763 13.9915 0.683092C13.5468 0.854195 13.2362 1.12687 13.0046 1.3175C12.9464 1.3636 12.9402 1.41204 12.9464 1.4847C13.5537 4.11296 15.4846 5.56225 16.9691 7.38422Z" fill="white" />
                                    </svg>
                                    <span className="ms-3 d-none d-md-block">Book Entertainment</span>
                                </button>
                                }

                                <button className="btn btn-primary gigpig-btn d-flex" onClick={toggleSummary}>
                                    <span>{summary === 0 ? 'Summarise report' : 'Detailed report'}</span>                              
                                </button>
                            </div>
                        </div>
                        {isMobileOnly && (
                            <div className="d-flex d-lg-none justify-content-around mobile-collapse">
                                {/* Hamburger icons for toggling filters and stats */}

                                <Button style={{ height: '45px', padding: '0px 15px' }} variant="primary apply-button me-2" onClick={() => toggleMenu('filters')} >
                                    <BsFillFilterCircleFill color="white" />
                                </Button>
                                <Button style={{ height: '45px', padding: '0px 15px' }} variant="primary apply-button me-2" onClick={() => toggleMenu('stats')} >
                                    <BsBarChartLineFill color="white" />
                                </Button>
                                <Button style={{ height: '45px', padding: '0px 15px' }} variant="primary apply-button mb-2 d-lg-none d-block" >
                                    <FaDownload color="white" />
                                </Button>
                            </div>
                        )}
                        {/* Render mobile menu and statistics bar if isMobile */}
                        {isMobileOnly ? 
                            <div>
                                {/* Render mobile menu if mobileFilters is open */}
                                {mobileFilters && (
                                    <div className="mobile-menu">
                                        <Filterstop
                                            onDatachange={handeldatachange}
                                            DataBefore={mangerreport}
                                            DataAfter={DataAfterFilter}
                                            userparameter={params}
                                            setLoading={setLoading}
                                            deviceInfo={deviceInfo}
                                            setdataafter={setDataAfterFilter}
                                            toggleFocusMode={toggleFocusMode}
                                            setFocusMode={setFocusMode}
                                            summary={summary}
                                        />
                                    </div>
                                )
                                }

                                {/* Render statistics bar if mobileStats is open */}
                                {mobileStats && (
                                    <div className="statistics-bar d-block flex-wrap align-items-center p-1 p-md-3 mt-3">
                                        <Bannerdata />
                                    </div>
                                )}
                            </div>
                         : 
                            <div>
                                {/* Render Filters and Statistics components */}
                                <Filterstop
                                    onDatachange={handeldatachange}
                                    DataBefore={mangerreport}
                                    DataAfter={DataAfterFilter}
                                    userparameter={params}
                                    setLoading={setLoading}
                                    deviceInfo={deviceInfo}
                                    setdataafter={setDataAfterFilter}
                                    toggleFocusMode={toggleFocusMode}
                                    setFocusMode={setFocusMode}
                                    summary={summary}
                                />
                                <div className="statistics-bar d-flex flex-wrap align-items-center p-1 p-md-2 mt-3" style={{ maxWidth: "1280px" }} >
                                    <Bannerdata />
                                </div>
                            </div>
                        } 
                    </Col>
                </Row>  
            </div>

            {/*<div className={`${focusMode ? 'd-none' : 'd-block'} w-100 text-center p-2`}>*/}
            {/*    <Button variant="focus-button" onClick={toggleFocusMode}>*/}
            {/*        <RiFocus3Line /> Exit Focus Mode*/}
            {/*    </Button>*/}
            {/*</div> */}

            <div className="manager-reports-container d-flex flex-column">
                <div className="d-flex flex-grow-1">
                    <div className="mx-4 report-table-container">
                        { ReponseMessage ? <ReportNotice /> :  isMobileOnly ? <ReportTableCard/> : <ReportTableRow /> }
                    </div>

                </div>
                <div className={focusMode ? 'd-block' : 'd-none'}>
                    <div className= "footer d-lg-flex justify-content-between align-items-center px-4 my-3">
                        <div className="px-4 py-2 rounded bg-light">
                            <div className="tableKey d-flex justify-content-between">
                                <p className="mb-0 me-md-4 me-1 d-none d-md-block"><b>Key</b></p>
                                <div className="d-flex align-items-center me-md-4 me-1">
                                    <BsCircleFill color="blue" />
                                    <p>New</p>
                                </div>
                                <div className="d-flex align-items-center me-md-4 me-1">
                                    <BsCreditCardFill color="green" />
                                    <p>Credited</p>
                                </div>
                                <div className="d-flex align-items-center me-md-4 me-1">

                                    <BsClockFill color="orange" />
                                    <p>Late</p>
                                </div>
                                <div className="d-flex align-items-center me-md-4 me-1">

                                    <BsExclamationTriangleFill color="red" />
                                    <p>Missing</p>
                                </div>
                                <div className="d-flex align-items-center">

                                    <BsCalendarWeekFill color="black" />
                                    <p>Future</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img className="w-100 mt-3 mt-lg-0" src={opusLogos} alt="Opus Apiero" />
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )

}

export const RatingModal = ({ showModal, onHide, rating, onSubmit, lineid, rl }) => {
    const [feedback, setFeedback] = useState('');
    useEffect(() => {
        if (!showModal) {
            setFeedback(''); // Reset feedback when modal is closed
        }
    }, [showModal]);
    const handleSubmit = () => {
        onSubmit(rating, lineid, rl, feedback);
        onHide(); // Close the modal after submitting
    };
    const renderContent = (ratingVal) => {

        if (ratingVal === 1 || ratingVal === 5) {
            return (
                <div className="d-block">
                    <p className="mb-3">{ratingVal === 1 ? 'Your rating is very low. Please provide feedback.' : 'Thank you for your excellent rating!'}</p>
                    <Form.Control as="textarea" rows={5} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                </div>
            );
        }
        return null;

    };

    return (
        <Modal show={showModal} onHide={onHide}>
            <Modal.Header closeButton closeVariant='white'>
                <Modal.Title>Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContent(rating)} {/* Call the renderContent function */}
            </Modal.Body>
            <Modal.Footer>
                {/* Submit form and send rating to database along with comments added for 1 / 5 stars */}
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleSubmit(rating, lineid, rl, feedback)}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const SignoffModal = ({ showModal, onHide, rowdata, emailAddress, deviceinfo, setLoading, settingsvalues, Updatelineitem }) => {
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
    const [signoffdata, setsignoffdata] = useState([]);
    const sigRef = useRef();

     const signOffDataIntialstate = useMemo(() => ({
        LineID: null,
        BookingReference: null,
        EmailAddress: emailAddress,
        SignOffMeta: deviceinfo,
        SignOffDate: null,
        SignOffBy: null,
        SignOffSignature: null,
        Comments: null,
        Rating: null
    }), [rowdata]);

    useEffect(() => {
        setsignoffdata(signOffDataIntialstate);
    }, [rowdata])
    const handleSignatureEnd = () => {
        const canvas = sigRef.current.getCanvas(); // Get the canvas element
       

        // Create a temporary canvas to draw the white background and signature
        const tempCanvas = document.createElement('canvas');
       const tempTransferredcontext = tempCanvas.getContext('2d');

        // Set dimensions to match the original canvas
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;

        // Draw a white rectangle covering the whole canvas
        tempTransferredcontext.fillStyle = '#FFFFFF'; // Set the color to white
        tempTransferredcontext.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

        // Draw the original signature on top of the white background
        tempTransferredcontext.drawImage(canvas, 0, 0);

        // Convert the temp canvas to a Data URL
        const data = tempCanvas.toDataURL();
        updateFormvalues('SignOffSignature', data)

    }
  

    const updateFormvalues = (key, value) => {

        // Check if the value is truthy before updating the state
        setsignoffdata(prevState => ({
            ...prevState,
            [key]: value

        }));

    };

    const handleReturnToMainModal = () => {
        setShowDisclaimerModal(false);

    };
    const handleShowDisclaimerModal = () => {
        updateFormvalues('LineID', rowdata.lineId);
        setShowDisclaimerModal(true);
    };
    const handleCloseDisclaimerModal = () => setShowDisclaimerModal(false);


    const handleConfirmDisclaimer = async () => {
        setShowDisclaimerModal(false);
        onHide();

   
        try {
            var res = await fetch(`${apiUrl}/StonegateReport/Updatemangersignoff`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(signoffdata)
            });

            const response = await res.json();
            if (!response.statusCode === 200) {
                setLoading(false);
                window.alert(response.value);
            }
            else {
                setLoading(false);
                Updatelineitem(signoffdata.LineID, signoffdata.Rating, signoffdata.SignOffSignature);

            }
        }
        catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    };


    const clearSignature = () => {
        sigRef.current.clear();
        updateFormvalues('SignOffSignature', null)
    }
    return (
        <>
            <Modal show={showModal} onHide={onHide}>
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title>Manager Sign Off</Modal.Title>
                </Modal.Header>
                <Alert key="danger" variant="danger mx-3 mt-2" >{settingsvalues.ManagerApprovalDisclaimer}</Alert>
                <Modal.Body>
                    <Form style={{width: '100%'}}>

                        <Form.Group className="mb-3">
                            <Form.Label>Rating</Form.Label>
                            <ReactStars
                                count={5}
                                onChange={(ratingvalue) => updateFormvalues('Rating', ratingvalue)}
                                value={signoffdata.Rating ?? rowdata.rating}
                                size={17}
                                half={false}
                                color2="#e23193"
                                color1="#999"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Feedback</Form.Label>
                            <Form.Control as="textarea" rows={3} value={signoffdata.Comments ?? ""} onChange={(e) => updateFormvalues('Comments', e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 w-100">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <Form.Label className="mb-0">Signature</Form.Label>
                                <Button onClick={clearSignature} title="Clear signature" className="btn btn-outline p-0" style={{ backgroundColor: "none", color: "white" }}><BsArrowCounterclockwise /></Button>

                            </div>

                            <div className="border p-2 signature-canvas" style={{ backgroundColor: 'white', borderRadius: "6px", height:"150px" }}>           
                                <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{                                                                      

                                        className: 'signatureCanvas'
                                    }}
                                    ref={sigRef}
                                    onEnd={handleSignatureEnd}
                                />
                            </div>
                        </Form.Group>
                        <Row className="mb-3">
                            <Col>
                                <Button variant="danger w-100" onClick={() => onHide()}>Close</Button>
                            </Col>
                            <Col className="text-right">
                                <Button variant="success w-100" onClick={handleShowDisclaimerModal}>Approve</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>

            </Modal>
            <Modal show={showDisclaimerModal} onHide={handleCloseDisclaimerModal} size="lg">
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title>Disclaimer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{settingsvalues.ManagerApprovalDisclaimerConfirmation}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleReturnToMainModal}>NO</Button>
                    <Button variant="primary" onClick={handleConfirmDisclaimer}>YES</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};


export const Filterstop = ({ onDatachange, DataBefore, DataAfter, userparameter, setLoading, deviceInfo, summary }) => {

    const [sitesDdl, setsitesDdl] = useState([]);
    const [roleDdl, setroleDdl] = useState([]);
    const [departmentDdl, setDepartmentDdl] = useState([]);
    const [ReportedDdl, setReportedDd] = useState([]);
    const [PeriodDdl, setPeriodDdlDdl] = useState([]);
    const [weekworkedDdl, setweekworkedDdl] = useState([]); 
    const [CategoryDdl, setCategoryDdl] = useState([]);

    const filteringcolums = useMemo(() => ({
        department: '',
        site: '',
        role: '',
        reportedToFinance: '',
        period: '',
        weekending: '',
        future: false,
    }), []);

    const [filtervalues, setfiltervalues] = useState(filteringcolums);

    const updateFilterValues = (key, value) => {

        // Check if the value is truthy before updating the state
        setfiltervalues(prevState => ({
            ...prevState,
            [key]: value

        }));

    };
    
    const ClearindividualFiltervalues = (key, value) => {
        // Check if the value is truthy before updating the state
        if (key === 'role') {

            setfiltervalues(prevState => ({
                ...prevState,
                department: ''

            }));
        }
        setfiltervalues(prevState => ({
            ...prevState,
            [key]: value

        }));


    };

    useEffect(() => {
        if (DataAfter) {
            setsitesDdl(Array.from(new Set(DataAfter.map(item => item.site))));
            setroleDdl(Array.from(new Set(DataAfter.map(item => item.role))));
            setReportedDd(Array.from(new Set(DataAfter.map(item => item.reportedToFinance))));
            setPeriodDdlDdl(Array.from(new Set(DataAfter.map(item => item.period))));
            setweekworkedDdl(Array.from(new Set(DataAfter.map(item => item.weekending))));
            setDepartmentDdl(Array.from(new Set(DataAfter.map(item => item.department))));
            setCategoryDdl(Array.from(new Set(DataAfter.map(item => item.cat).filter(cat => cat !== ''))));


        }

    }, [DataAfter]);

   
    useEffect(() => {
        if (JSON.stringify(filteringcolums) !== JSON.stringify(filtervalues)) {

            const datevaluecolumns = ['reportedToFinance', 'weekending'];
            const filteredtablevalues = DataBefore.filter(item => {
                return Object.keys(filtervalues).every(key => {

                    if (!filtervalues[key]) return true;
                    if (datevaluecolumns.includes(key)) {

                        if (item[key] === null) {

                            return false;


                        }
                        else if (filtervalues[key].includes("future") === 1) {

                            return item[key] === true
                        }
                        else {
                            // compare only the date part, ignoring the time
                            const itemDate = item[key].split('T')[0];
                            const filterDate = filtervalues[key].split('T')[0];
                            return itemDate === filterDate;
                        }
                    }

                    else {
                        // If the filter is set, only include items that match
                        return item[key] === filtervalues[key];
                    }

                });
            });
            onDatachange(filteredtablevalues, false);
            //setDataAfterFilter(filteredtablevalues);
            /*    setcopyoffilters(filtervalues);*/
        }
        else {
            onDatachange(DataBefore, false);
        }

    }, [filtervalues]);


    useEffect(() => {
        handelclearbutton();
    }, [summary]);

    const formatethedate = (passeddate) => {
        if (!passeddate) {
            return passeddate;
        }
        else {
            const datePart = passeddate.split('T')[0];
            const [year, month, day] = datePart.split('-');
            const shortYear = year.substring(2); // Take only the last two digits of the year
            return `${day}/${month}/${shortYear}`;
        }
    }
    const handelclearbutton = () => {
        if (JSON.stringify(filteringcolums) !== JSON.stringify(filtervalues)) {
            setLoading(true);
            onDatachange(DataBefore, true);
            setfiltervalues(filteringcolums);
        }

    }

    // return text based on cat
    const getTextBasedOnCat = (cat) => {
        switch (cat) {
            case "New":
                return "New Charges";
            case "Credited":
                return "Credited";
            case "Late":
                return "Received Late";
            case "Missing":
                return "Missing";
            case "Future Booking":
                return "Future Bookings";
            default:
                return ""; // Handle the case where the cat value doesn't match any of the expected values
        }
    };


    return (
        <>

            <div className="reports-filters-container d-flex flex-wrap align-items-end justify-content-between ">
                <div className="d-flex w-100">
                    <div className="report-filters d-flex flex-wrap align-items-end gap-3 my-2 w-100">
                        <Dropdown>
                            <div className="d-flex align-items-center justify-content-between justify-content-between">
                                <Form.Label>Cost Type</Form.Label>
                                {filtervalues.department && (<Form.Label className="text-danger bg-white px-2 rounded FilterClearButton" onClick={() => ClearindividualFiltervalues('department', '')}>Clear</Form.Label>)}
                            </div>
                            <Dropdown.Toggle variant="default" id="dropdown-basic">
                                <div>{filtervalues.department || 'Select Cost Type'}</div>

                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {departmentDdl.map((items) => <Dropdown.Item onClick={(e) => updateFilterValues('department', items)}>{items}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown>
                            <div className="d-flex align-items-center justify-content-between">
                                <Form.Label>Sites</Form.Label>
                                {filtervalues.site && (<Form.Label className="text-danger bg-white px-2 rounded FilterClearButton" onClick={() => ClearindividualFiltervalues('site', '')}>Clear</Form.Label>)}
                            </div>
                            <Dropdown.Toggle variant="default me-0" id="dropdown-basic">
                                <div>{filtervalues.site || 'Select Site'}</div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {sitesDdl.map((items) => <Dropdown.Item onClick={() => updateFilterValues('site', items)}>{items}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <div className="d-flex align-items-center justify-content-between">
                                <Form.Label>Role</Form.Label>
                                {filtervalues.role && (<Form.Label className="text-danger bg-white px-2 rounded FilterClearButton" onClick={() => ClearindividualFiltervalues('role', '')}>Clear</Form.Label>)}
                            </div>

                            <Dropdown.Toggle variant="default me-0" id="dropdown-basic">
                                <div >{filtervalues.role || 'Select Role'}</div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {roleDdl.map((items) => <Dropdown.Item onClick={(e) => updateFilterValues('role', items)}>{items}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <div className="d-flex align-items-center justify-content-between">
                                <Form.Label>Reported</Form.Label>
                                {filtervalues.reportedToFinance && (<Form.Label className="text-danger bg-white px-2 rounded FilterClearButton" onClick={() => ClearindividualFiltervalues('reportedToFinance', '')}>Clear</Form.Label>)}                       
                            </div>
                            <Dropdown.Toggle variant="default" id="dropdown-basic">
                                <div className="fixed-width">{formatethedate(filtervalues.reportedToFinance) || 'Select Date'}</div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {ReportedDdl
                                    .filter(dateStr => dateStr !== null) // Filter out null values
                                    .sort((a, b) => a.localeCompare(b)) // Sort date strings in ascending order
                                    .map(item => (
                                        <Dropdown.Item
                                            key={item}
                                            onClick={(e) => updateFilterValues('reportedToFinance', item)}
                                        >
                                            {formatethedate(item)}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown>
                            <div className="d-flex align-items-center justify-content-between">
                                <Form.Label>Period</Form.Label>
                                {filtervalues.period && (<Form.Label className="text-danger bg-white px-2 rounded FilterClearButton" onClick={() => ClearindividualFiltervalues('period', '')}>Clear</Form.Label>)}
                            </div>

                            <Dropdown.Toggle variant="default" id="dropdown-basic">
                                <div >{filtervalues.period || 'Select Date'}</div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {PeriodDdl
                                    .slice()
                                    .sort((a, b) => b.localeCompare(a)) //for des order
                                    .map((items) =>
                                        <Dropdown.Item
                                            onClick={(e) => updateFilterValues('period', items)}>
                                            {items}</Dropdown.Item>
                                    )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <div className="d-flex align-items-center justify-content-between">
                                <Form.Label>Week</Form.Label>
                                {filtervalues.weekending && (<Form.Label className="text-danger bg-white px-2 rounded FilterClearButton" onClick={() => ClearindividualFiltervalues('weekending', '')}>Clear</Form.Label>)}
                            </div>

                            <Dropdown.Toggle variant="default" id="dropdown-basic">
                                <div>{formatethedate(filtervalues.weekending) || 'Select Date'}</div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {weekworkedDdl
                                    .filter(dateStr => dateStr !== null) // Filter out null values
                                    .sort((a, b) => b.localeCompare(a)) //for des order
                                    .map(item => (
                                        <Dropdown.Item
                                            key={item}
                                            onClick={(e) => updateFilterValues('weekending', item)}>
                                            {formatethedate(item)}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <div className="d-flex align-items-center justify-content-between">                             
                                <Form.Label>Category</Form.Label>
                                {filtervalues.cat && (<Form.Label className="text-danger bg-white px-2 rounded FilterClearButton" onClick={() => ClearindividualFiltervalues('cat', '')}>Clear</Form.Label>)}
                            </div>
                            <Dropdown.Toggle variant="default" id="dropdown-basic">
                                <div className="fixed-width">{getTextBasedOnCat(filtervalues.cat) || 'Select Category'}</div>
                            </Dropdown.Toggle>
                            
                            <Dropdown.Menu>
                                {CategoryDdl.map((items) => <Dropdown.Item onClick={(e) => updateFilterValues('cat', items)}>{getTextBasedOnCat(items)}</Dropdown.Item>)}
                            </Dropdown.Menu>

                        </Dropdown>
                        <Button variant="clear-button" onClick={() => { handelclearbutton() }}>
                            Clear All
                        </Button>
                        <Form.Check
                            type="checkbox"
                            id="future-bookings"
                            checked={filtervalues.future}
                            label="Future jobs"
                            onChange={(e) => updateFilterValues('future', !filtervalues.future)}
                        />
                    </div>

                </div>
            </div>


        </>
    )
}
export default ManagerReport;